import { useState, useRef, useEffect, ChangeEvent, FormEvent, FC } from 'react';
import { logger } from '../../../../components/logger';
import { getAuthToken } from 'reactjs/utils';
import { captureException } from '@sentry/browser';
import { validator, showError } from '../DeviseHelper';
import TextField from '@polydice/ui/dist/TextField';
import HelperText from '@polydice/ui/dist/HelperText';
import Button from '@polydice/ui/dist/Button';
import {
  EMAIL_REGEX,
  EMAIL_ERROR_EXIST,
  NICKNAME_REGEX,
  SignInState
} from '../constants';
import styles from '../style.module.scss';

interface PasswordSignUpProps {
  signUpEndpoint: string;
  signUpSuccessPath: string;
  email: string;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEmailBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
  emailHelperText: string;
  setEmailHelperText: (text: string) => void;
  setSignInState: (state: SignInState) => void;
  RECAPTCHA_SITE_KEY: string;
}

const PasswordSignUp: FC<PasswordSignUpProps> = ({
  signUpEndpoint,
  signUpSuccessPath,
  email,
  onEmailChange,
  onEmailBlur,
  emailHelperText,
  setEmailHelperText,
  setSignInState,
  RECAPTCHA_SITE_KEY
}) => {
  const recaptcha: { current: any } = useRef(null);
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);

  const [nickname, setNickname] = useState('');
  const [nicknameHelperText, setNicknameHelperText] = useState('');
  const onNicknameChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setNickname(value);
  };
  const onNicknameBlur = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setNicknameHelperText(validator('nickname', value));
  };

  const [showPassword, setShowPassword] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const onPasswordChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setPassword(value);
  };
  const onPasswordBlur = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setPasswordHelperText(validator('password', value));
  };

  const [recaptchaResponse, setRecaptchaResponse] = useState('');

  useEffect(() => {
    const recaptchaCallbackName = 'onRecaptchaLoaded';

    window[recaptchaCallbackName] = () => {
      window.grecaptcha.render(recaptcha.current, {
        sitekey: RECAPTCHA_SITE_KEY,
        callback: (response: any) => setRecaptchaResponse(response)
      });
    };

    const recaptchaScriptTag = document.createElement(
      'SCRIPT'
    ) as HTMLScriptElement;
    recaptchaScriptTag.src = `https://www.google.com/recaptcha/api.js?onload=${recaptchaCallbackName}&render=explicit`;
    recaptchaScriptTag.async = true;
    recaptchaScriptTag.defer = true;
    document.body.appendChild(recaptchaScriptTag);
  }, [RECAPTCHA_SITE_KEY]);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setEmailHelperText('');
    showError(false);
    setIsWaitingResponse(true);

    const formData = new FormData();
    formData.append('user[email]', email.trim());
    formData.append('user[nickname]', nickname.trim());
    formData.append('user[password]', password.trim());
    formData.append('authenticity_token', getAuthToken());
    formData.append('g-recaptcha-response', recaptchaResponse);

    try {
      const response = await fetch(signUpEndpoint, {
        method: 'POST',
        headers: {
          'X-XSRF-Token': getAuthToken()
        },
        credentials: 'same-origin',
        body: formData
      });

      if (response.ok) {
        const result = await response.json();
        logger.logEvent('SignInUp_select', {
          signinup_selection: 'email'
        });
        window.location.replace(
          `${signUpSuccessPath}?redirect=${encodeURIComponent(
            result.redirect_url
          )}`
        );
      } else {
        setEmailHelperText(EMAIL_ERROR_EXIST);
        setRecaptchaResponse('');
        window.grecaptcha.reset();
      }
    } catch (error) {
      showError(true);
      captureException(error);
    }

    setIsWaitingResponse(false);
  };

  const returnToMagicLinkPage = () => {
    setSignInState('EmailSignInMagicLink');
    showError(false);
    setEmailHelperText('');
    setPasswordHelperText('');
  };

  return (
    <div>
      <div className={styles.signInHeader}>
        <button
          className={styles.signInHeaderReturnBtn}
          onClick={() => returnToMagicLinkPage()}
        >
          <i className="icon-chevron-left-regular"></i>
          <span>返回</span>
        </button>
        <h1>使用電子信箱註冊</h1>
        <div className={styles.signInHeaderEmpty}></div>
      </div>
      <form className={styles.deviseMain} method="POST" onSubmit={onSubmit}>
        <TextField
          className={`${styles.deviseMainTextField} ${
            emailHelperText ? 'mdc-text-field--invalid' : ''
          }`}
          label="Email"
          type="email"
          inputMode="email"
          name="new-email"
          pattern={EMAIL_REGEX.source}
          required
          value={email}
          onChange={onEmailChange}
          onBlur={onEmailBlur}
        />
        <HelperText
          className={styles.deviseMainHelperText}
          text={emailHelperText}
        />
        <TextField
          className={styles.deviseMainTextField}
          label="暱稱"
          name="new-nickname"
          pattern={NICKNAME_REGEX.source}
          placeholder="最多 16 個字"
          required
          value={nickname}
          onChange={onNicknameChange}
          onBlur={onNicknameBlur}
        />
        <HelperText
          className={styles.deviseMainHelperText}
          text={nicknameHelperText}
        />
        <TextField
          className={styles.deviseMainTextField}
          label="密碼"
          type={showPassword ? 'text' : 'password'}
          name="new-password"
          placeholder="至少 6 個字元以上"
          minLength={6}
          required
          tail={{ type: 'text', value: showPassword ? '隱藏' : '顯示' }}
          onTailClick={() => setShowPassword((value) => !value)}
          value={password}
          onChange={onPasswordChange}
          onBlur={onPasswordBlur}
          aria-describedby="password-constraints"
        />
        <HelperText
          id="password-constraints"
          className={styles.deviseMainHelperText}
          text={passwordHelperText}
        />
        <div className={styles.deviseMainRecaptcha} ref={recaptcha} />
        <Button
          className={styles.deviseMainButton}
          type="submit"
          styleType="contained"
          size="lg"
          color="primary"
          unfulfilled={isWaitingResponse || !recaptchaResponse}
          fullWidth
        >
          註冊
        </Button>
        <p className={styles.deviseMainTerm}>
          繼續進行代表你同意
          <a
            className={styles.deviseMainTermLink}
            href="https://newsroom.icook.tw/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            服務條款
          </a>
        </p>
      </form>
    </div>
  );
};

export default PasswordSignUp;
